

import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { callApi,detectClientOS } from "@/api/api"


export default {
    name: 'Home',
    components: {
         
    },
    
    setup() {

        const store = useStore()
        const router = useRouter()
        const auth = computed(() => store.state.authenticated)
        const authUser = computed(() => store.state.authUser)
        //const langs = computed(() => store.state.translation.langs);
        const locale = computed(() => store.state.translation.locale)


        const data = reactive({
            endpoint: '/dashboard',
            records: [],
            loading: false,
            clientOS: '',
            clientOSImage: '',
            clientOSLink : '',
        })

        const scrollTo = (sectionId) => {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            } else {
                console.error(`No section found with the id ${sectionId}`);
            }
        }

        onMounted(async () => {
            console.log(`new version 2 of website===================`);
            data.loading = true
            //let lang = authUser.value.preferred_language ? authUser.value.preferred_language : "en";
            //store.dispatch(`translation/getLangs`, { userLang: lang });
            //let resData = await callApi(data.endpoint, 'GET')
            //data.records = resData.data
            detectClientOS(data,locale);
            data.loading = false
        })
 

   

        return {
            auth,
            data,
            locale,
            authUser,
            scrollTo,
            detectClientOS
        }
    }
}

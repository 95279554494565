

import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex' 
import {useRouter, useRoute} from 'vue-router'
import Breadcrumb from "@/components/layout/Breadcrumb.vue"

import { callApi,detectClientOS ,updateDownloadReport} from "@/api/api"
import { channel } from 'diagnostics_channel'

export default {
    name: 'android',
    components: {
        Breadcrumb
    },
    
    setup() {

        const store = useStore()  
        const locale = computed(() => store.state.translation.locale) 
        const route = useRoute()

        const data = reactive({ 
            loading: false,  
            page:1,
            channel:'',
            hover:false,
            clientOS: '',
            clientOSImage: '',
            clientOSLink : '',
            d:'n',// auto download
            images:[
                '/images/tutorials/android/1.svg',
                '/images/tutorials/android/2.svg',
                '/images/tutorials/android/3.svg',
                '/images/tutorials/android/4.svg',
                '/images/tutorials/android/5.svg',
                '/images/tutorials/android/6.svg',
                '/images/tutorials/android/7.svg',
                '/images/tutorials/android/8.svg',
                '/images/tutorials/android/9.svg',
                '/images/tutorials/android/10.svg',
                '/images/tutorials/android/11.svg',
                '/images/tutorials/android/12.svg',
                '/images/tutorials/android/13.svg',
                '/images/tutorials/android/14.svg',
                '/images/tutorials/android/15.svg',
                '/images/tutorials/android/16.svg',
                '/images/tutorials/android/17.svg',
                '/images/tutorials/android/18.svg',
            ]
        })
 

        onMounted(async () => {

            data.loading = true  
            
            data.page=  parseInt(route.query.page as string)  || 1 
            data.channel =   route.query.channel && typeof(route.query.channel)!='undefined' ? route.query.channel as string : '';
             
            data.d = route.query?.d as string 
            //console.log("onMounted windows page is 1====",route.query) 
            if (data.d == 'y')
            detectClientOS(data, locale, "android").then(() => {
                console.log("detectClientOS===",  data.clientOSLink)
                //window.open( 'http://'+window.location.host+data.clientOSLink, '_blank')
                // Create a link element
                const link = document.createElement('a');
                link.href =  data.clientOSLink;  
                link.style.display = 'none'; // Ensure it's hidden 
                // Use a unique ID to avoid conflicts
                link.id = 'auto-download-link'; // Use a unique ID to avoid conflicts  
                // Append to the body
                document.body.appendChild(link); 
                // Trigger the download
                link.click(); 
                // Remove the link
                document.body.removeChild(link);

                // save to download report 
                updateDownloadReport(window.location.href,'android',data.channel);

            });
            data.loading = false
        })

        const handleMouseOver=()=>{
         console.log("handleMouseOver===")
         data.hover = true
        }

        const handleMouseLeave=()=>{
            console.log("handleMouseLeave===")
            data.hover = false
       }
 
 

        const openImageInNewTab=(fullImageSrc: string)=>{
          window.open(fullImageSrc, '_blank');
        }
 

        return { 
            data,
            locale,
            openImageInNewTab,
            handleMouseOver,
            handleMouseLeave,
        }
    }
}

<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
 <div class="view-header-title-box">
       

        <h2> {{locale.dashboard}} </h2></div>
        <!--  

        <div class="row mt-5">
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-primary text-white h5">
                       {{locale.active}} {{locale.users}} (24H)
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{data.records.total_online_users}}</h5>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-secondary text-white h5">
                        {{locale.today}} {{locale.new}} {{locale.users}} 
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{data.records.today_new_usres}}</h5>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-info text-dark h5">
                        {{locale.today}} {{locale.not}} {{locale.confirmed}} {{locale.users}} 
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{data.records.today_not_confirmed_usres}}</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-warning text-dark h5">
                        {{locale.today}} {{locale.orders}}
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{data.records.today_orders}}</h5>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-success text-white h5">
                        {{locale.today}} {{locale.paid}} {{locale.orders}}
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{data.records.today_paid_orders}}</h5>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-danger text-white h5">
                    {{locale.today}} {{locale.un_paid}} {{locale.orders}}
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{data.records.today_unpaid_orders}}</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-primary text-white h5">
                        {{locale.today}} {{locale.un_paid}} {{locale.amount}}
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">$ {{data.records.today_unpaid_amount}}</h5>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-secondary text-white h5">
                        {{locale.today}} {{locale.paid}} {{locale.amount}}
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">$ {{data.records.today_paid_amount}}</h5>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-info text-dark h5">
                    {{locale.today}} {{locale.refund}} {{locale.amount}}
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">$ {{data.records.today_refund_amount}}</h5>
                    </div>
                </div>
            </div>
        </div>
 -->
    </main>
</template>

<script>

import {onMounted, reactive, computed} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'

import {callApi } from "@/api/api"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'Dashboard',
    
      components: {
       
    },  
    setup() {

        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        //const langs = computed(() => store.state.translation.langs);
        const locale = computed(() => store.state.translation.locale)


        const data = reactive({
            endpoint: '/dashboard',
            records: [],
            loading: false,
        })

        onMounted( async() =>{
            if(!auth.value){
                await router.push('/login')
            }
            /* let isAllowAccessPage = await checkAccessSinglePage('dashboard', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/') */

            data.loading = true
            //let lang = authUser.value.preferred_language ? authUser.value.preferred_language : "en";
            //store.dispatch(`translation/getLangs`, { userLang: lang });
            let resData = await callApi(data.endpoint, 'GET')
            data.records = resData.data
            data.loading = false
        })

        return {
            auth,
            data,
            locale,
            authUser
        }
    }
}
</script>

<style scoped>
.circle-btn{
    border-radius: 50%;
    height: 110px;
    width: 110px;
    min-width: 110px;
}
</style>

import { defineComponent, ref, onMounted, computed ,reactive, onUpdated } from "vue";
import { useRouter,useRoute } from "vue-router"; 
import { useStore } from 'vuex' 
import { saveVisitor } from "@/api/api"

import FadeDown from "@/components/transitions/FadeDown.vue"; 
 

export default defineComponent({
  components: {
    FadeDown,
  },
  props: {
    direction: String ,
    },
    
  computed: {
    p() {
      console.log("queryParam============",this.$route.query) 
     // console.log("channel ============",this.data.channel , ) 
    if (this.data.saveVisitorCounter == 1)
    {
       if(this.$route.query && typeof(this.$route.query.channel)!='undefined'){ 
       console.log("app channel detect=========",this.$route.query.channel)   
       saveVisitor(this.$route.query?.channel && typeof(this.$route.query?.channel)!='undefined'?this.$route.query.channel as string:'') 
       this.data.saveVisitorCounter = 2
      }
      else
      {
        saveVisitor('')
        this.data.saveVisitorCounter = 2
      }
    }
    else
     this.data.saveVisitorCounter = 1
     

      return this.$route.query.p || 1; // Replace 'yourQueryParam' with the actual query parameter key
    }
  },
  setup(props) {
    let dsp = ref("none");
    let langDropdown = ref("none");
    // let url=ref("");
    let stagingDomain= ref(false);
    const router = useRouter();  
    const route = useRoute();
    const isShown = ref(false);
    const isLangShown = ref(false);
    const isMouseOn = ref(false);
    const isMouseOnLang = ref(false);
    const isMobNavShown = ref(false);  
      const store = useStore()
      const auth = computed(() => store.state.authenticated)
      const username = computed(() => store.state.authUser ? store.state.authUser.username : '')
      const isAdmin = computed(() => store.state.isAdmin)
      const locale = computed(() => store.state.translation.locale);

      const data = reactive({  
            hover:false,
            p:0,
            saveVisitorCounter : 0,
            channel:'',
      })


      const redirect = (page) => {
          router.push(`${page}`);
      };

      const logout = async () => {

          localStorage.removeItem('token');
          localStorage.removeItem('user');
          await store.dispatch("setAuth", false);
          await store.dispatch("setAdmin", false);

      }

    const showMenu = () => {
      isMouseOn.value = true;
      isShown.value = true;
      dsp.value = "block";

      // setTimeout(() => {
      //   if (isMouseOn.value) isShown.value = true;
      // }, 300);
    };
    const showLangdropdown = () => {
      isMouseOnLang.value = true;
      isLangShown.value = true;
      langDropdown.value = "block";

      // setTimeout(() => {
      //   if (isMouseOn.value) isShown.value = true;
      // }, 300);
    };

    const toggleMobileMenu = () => {
      isMobNavShown.value = !isMobNavShown.value;
    };

    const hideMenu = () => {
      isMouseOn.value = false;
      setTimeout(() => {
        if (!isMouseOn.value) isShown.value = false;
      }, 200);
    };
    const hideLangdropdown = () => {
      isMouseOnLang.value = false;
      setTimeout(() => {
        if (!isMouseOnLang.value) isLangShown.value = false;
      }, 200);
    };

    const toggleMenu = () => {
      isMouseOn.value = !isMouseOn.value;
      isShown.value = !isShown.value;
    };
    const toggleLangMenu = () => {
      isMouseOnLang.value = !isMouseOnLang.value;
      isLangShown.value = !isLangShown.value;
    };
 

    const isMobSubMenuShown = ref(false);
    const isMobSubMenuShownLang = ref(false);
    const toggleMobSubMenu = () => {
      isMobSubMenuShown.value = !isMobSubMenuShown.value;
    };
    const toggleMobSubMenuLang = () => {
      isMobSubMenuShownLang.value = !isMobSubMenuShownLang.value;
    };
 

    onMounted( async() => {
      console.log("onMounted NAV Selected page is 1*******\n",route.query) 
      let lang = 'cn'
      await store.dispatch(`translation/getLangs`,{userLang:lang})

      if ((window.location.href.indexOf("staging") >= 0) || (window.location.href.indexOf("localhost") >= 0)) {
        stagingDomain.value= true;
      }else{
        stagingDomain.value= false;
      } 
    });

    const handleMouseOver=()=>{
         console.log("handleMouseOver===")
         data.hover = true
        }

    const handleMouseLeave=()=>{
            console.log("handleMouseLeave===")
            data.hover = false
       }



    return {  
      username,
      auth,
      isAdmin,
      logout,
      locale, 
      data,
      redirect,
      isShown,
      showMenu,
      hideMenu,
      toggleMenu,
      isMobNavShown,
      toggleMobileMenu,
      isMobSubMenuShown,
      toggleMobSubMenu,
      dsp,
      hideLangdropdown,
      showLangdropdown,
      langDropdown,
      isLangShown,
      toggleLangMenu, 
      toggleMobSubMenuLang,
      isMobSubMenuShownLang,
      handleMouseOver,
      handleMouseLeave,
    };
  },
});

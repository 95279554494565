


let apiHostUrl = 'http://localhost:7000/api'
//console.log("process.env ", process.env)
//console.log("window.location.href",window.location.href)

if(process.env.NODE_ENV=='production'){
    apiHostUrl = 'https://jc.juejueziapi.com/api'
}
else 
if(process.env.NODE_ENV=='development'){
    apiHostUrl = 'http://107.149.163.8:7000/api'
}
//http://192.168.0.131
if(window.location.href.indexOf("localhost") >= 0 || window.location.href.indexOf("192.168.0.111") >= 0) {
    apiHostUrl= 'http://localhost:7000/api'
}else if(window.location.href.indexOf("dev") >= 0){
    apiHostUrl = 'http://107.149.163.8:7000/api'
}
else if(window.location.href.indexOf("107.149.163.8") >= 0){
   // apiHostUrl = 'http://107.149.163.8:4000/website' // on test server withou nginx
   apiHostUrl = 'http://107.149.163.8:7000/api' // with nginx redirect from port 80 to port 4000
}
 else{ // production
    apiHostUrl = 'https://jc.juejueziapi.com/api'
}

//console.log("apiHostUrl", apiHostUrl)

export const apiHost = apiHostUrl

export const apiUploadUrl = apiHostUrl + '/upload/ckeditor'